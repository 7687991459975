<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Charges</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-md-12">
                          <base-input
                            alternative=""
                            label="Charge Code"
                            input-classes="form-control-alternative"
                            :value="model.charge_code"
                            @input="
                              (event) =>
                                (model.charge_code = event.target.value)
                            "
                          />
                          <!-- <div
                            class="error mb-2"
                            v-if="v$.model.charge_code.$error"
                          >
                            Charge Code is required.
                          </div> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Charge Name"
                            input-classes="form-control-alternative"
                            :value="model.charge_name"
                            @input="
                              (event) =>
                                (model.charge_name = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.charge_name.$error"
                          >
                            Charge Name is required.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Amount($)"
                            input-classes="form-control-alternative"
                            :value="model.charge_amount"
                            @input="
                              (event) =>
                                (model.charge_amount = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.charge_amount.$error"
                          >
                            Invalid amount.
                          </div>
                        </div>
                      </div>
                      <div class="row1">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            v-model="model.is_active"
                            false-value="2"
                            true-value="1"
                          />
                          <label class="custom-control-label" for="customCheck1"
                            >Active?</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const chargesRepository = RepositoryFactory.get("chargesRepo");
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
export default {
  name: "Charges",

  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      isUpdateLoading: false,
      model: {
        is_active: 2,
      },
    };
  },

  validations() {
    return {
      model: {
        charge_name: { required },
        // charge_code: { required },
        charge_amount: { required, numeric },
      },
    };
  },

  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }

      if (this.model.is_active != 1) {
        this.model.is_active = "2";
      }

      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);

      let response = await chargesRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/manageCharges/view_Charges");
        }, 1000);
      }
    },
    async Update() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }

      delete this.model.edit;
      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await chargesRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/manageCharges/view_Charges");
        }, 1000);
      }
    },
  },
  mounted() {
    this.id = this.$route.params;
    this.model = {};
    chargesRepository.getChargesByid(this.id.data).then((data) => {
      if (data.data.length != 0) {
        if (data.data[0]) {
          this.model = data.data[0];
        }
      }
    });
  },
};
</script>

<style></style>
